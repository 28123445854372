'use client';

import { Login } from '@deltasierra/react/features/login';
import { useTranslations } from '@deltasierra/translations/react';
import { useAppWebRouter } from '../hooks';

export default function LoginContainer(): JSX.Element {
    const AppWebRouter = useAppWebRouter();
    const t = useTranslations('Login');

    return <Login homeHref={AppWebRouter.get('/')} t={t} />;
}

LoginContainer.displayName = 'LoginContainer';
